<template>
  <div class="form">
<!--    <MyNavBar :title="typeFormat(form.type)" :leftArrow="false"/>-->
      <van-nav-bar
          :title="typeFormat(form.type)"
          :left-arrow="false"
          :fixed=true
          :placeholder=true
          @click-left="onClickLeft"
      />
    <div class="container">

      <div class="demo-item">
<!--        <p class="demo-desc">Form</p>-->
        <div class="demo-block">
          <van-form @submit="onSubmit">
<!--              <van-field-select-picker
                  label="切换类型"
                  placeholder="请选择"
                  v-model="form.type"
                  :columns="columns"
                  :option="{label:'name',value:'id'}"
                  @confirm="confirm"
              />-->
              <van-field name="switch" label="流程分类">
                  <template #input>
                      {{ typeFormat(form.type) }}
                  </template>
              </van-field>
              <van-field name="switch" label="申请人">
                  <template #input>
                      {{ form.createBy }}
                  </template>
              </van-field>
<!--              出差申请-->
              <div v-if="form.type === 1">
                  <van-field name="switch" label="开始时间">
                      <template #input>
                          {{ CCInfo.startTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="结束时间">
                      <template #input>
                          {{ CCInfo.endTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="出差地点">
                      <template #input>
                          {{ CCInfo.address }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="出差理由">
                      <template #input>
                          {{ CCInfo.reason }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="是否需要借款">
                      <template #input>
                          <span v-if="CCInfo.isLoan === 1">是</span>
                          <span v-if="CCInfo.isLoan != 1">否</span>
                      </template>
                  </van-field>
                  <van-field name="switch" label="借款金额" v-if="CCInfo.isLoan === 1">
                      <template #input>
                          {{ CCInfo.money }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="申请交通工具">
                      <template #input>
                          {{ typeFormat1(CCInfo.isVehicle) }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="起始地">
                      <template #input>
                          {{ CCInfo.startAddress }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ CCInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="CCInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="CCInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              加班申请-->
              <div v-if="form.type === 2">
                  <van-field name="switch" label="开始时间">
                      <template #input>
                          {{ JBInfo.startTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="结束时间">
                      <template #input>
                          {{ JBInfo.endTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="加班事由">
                      <template #input>
                          {{ JBInfo.reason }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ JBInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="JBInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="JBInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              离职申请-->
              <div v-if="form.type === 3">
                  <van-field name="switch" label="离职时间">
                      <template #input>
                          {{ LZInfo.time }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="离职事由">
                      <template #input>
                          {{ LZInfo.reason }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ LZInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="LZInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="LZInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              请假申请-->
              <div v-if="form.type === 4">
                  <van-field name="switch" label="请假类别">
                      <template #input>
                          {{ typeFormat4(QJInfo.type) }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="开始时间">
                      <template #input>
                          {{ QJInfo.startTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="结束时间">
                      <template #input>
                          {{ QJInfo.endTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="请假天数">
                      <template #input>
                          {{ QJInfo.day }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="请假事由">
                      <template #input>
                          {{ QJInfo.reason }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ QJInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="QJInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="QJInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              用印申请-->
              <div v-if="form.type === 5">
                  <van-field name="switch" label="印章名称">
                      <template #input>
                          {{ YZInfo.name }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="印章类别">
                      <template #input>
                          {{ typeFormat5(YZInfo.type) }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="申请时间">
                      <template #input>
                          {{ YZInfo.date }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="用章事由">
                      <template #input>
                          {{ YZInfo.reason }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="用印份数">
                      <template #input>
                          {{ YZInfo.num }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ YZInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="YZInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="YZInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              资产申请-->
              <div v-if="form.type === 6">
                  <van-field name="switch" label="资产名称" v-if="ZCInfo.bizEquipment">
                      <template #input>
                          {{ ZCInfo.bizEquipment.name }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="开始时间">
                      <template #input>
                          {{ ZCInfo.useTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="归还时间">
                      <template #input>
                          {{ ZCInfo.returnTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="使用用途">
                      <template #input>
                          {{ ZCInfo.useType }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ ZCInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="ZCInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="ZCInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              档案申请-->
              <div v-if="form.type === 7">
                  <van-field name="switch" label="档案名称" v-if="DAInfo.bizArchives">
                      <template #input>
                          {{ DAInfo.bizArchives.name }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="借阅时间">
                      <template #input>
                          {{ DAInfo.borrowTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="归还时间">
                      <template #input>
                          {{ DAInfo.returnTime }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ DAInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="DAInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="DAInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
<!--              报销申请-->
              <div v-if="form.type === 8">
                  <van-field name="switch" label="报销类别">
                      <template #input>
                          {{ typeFormat8(BXInfo.type) }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="报销项目" v-if="BXInfo.bizProject">
                      <template #input>
                          {{ BXInfo.bizProject.name }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="报销金额">
                      <template #input>
                          {{ BXInfo.price }}
                      </template>
                  </van-field>
                  <van-collapse v-model="activeNames">
                      <van-collapse-item title="报销明细" name="1">
                          <van-cell-group inset v-for="(item,index) in BXInfo.list" :key="index">
                              <van-cell :title="'明细:'+item.name" :label="'金额:'+item.price" />
                          </van-cell-group>
                      </van-collapse-item>
                  </van-collapse>
                  <div></div>
                  <van-field name="switch" label="备注">
                      <template #input>
                          {{ BXInfo.remark }}
                      </template>
                  </van-field>
                  <van-field name="switch" label="附件" v-if="BXInfo.file != ''">
                      <template #input>
                          <van-uploader v-model="BXInfo.file" :show-upload="false" />
                      </template>
                  </van-field>
              </div>
              <van-field name="radio" label="审批意见"
                         required
                         v-if="form.isNow === 1"
                         :rules="[{ required:true, message: '请选择正确内容' }]">
                  <template #input>
                      <van-radio-group v-model="form.opinion"
                                       required direction="horizontal">
                          <van-radio name="1">同意</van-radio>
                          <van-radio name="2">拒绝</van-radio>
                      </van-radio-group>
                  </template>
              </van-field>
              <van-field
                  v-if="form.opinion === '2'&&form.isOpinion===0"
                  v-model="form.remark"
                  rows="2"
                  autosize
                  label="审批备注"
                  type="textarea"
                  placeholder="请输入审批备注"
                  show-word-limit
              />
              <van-field name="switch" label="审批意见"
                         v-if="form.isNow===0">
                  <template #input>
                      <div style="color:#1ab394;" v-if="form.opinion ===1">同意</div>
                      <div style="color:#ff4949;" v-if="form.opinion ===2">拒绝</div>
                  </template>
              </van-field>
              <van-field name="switch" label="审批备注"
                         v-if="form.opinion === 2&&form.isOpinion===1">
                  <template #input>
                      {{ form.remark }}
                  </template>
              </van-field>
            <div style="margin: 16px;">
              <van-button round block type="info" v-if="form.isNow === 1" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import data from '../../public/data.json'
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';;
import VanFieldSelectPicker from '@/components/select/VanFieldSelectPicker.vue';


  export default {
    name: 'Form',
    components: {MyNavBar, VanFieldSelectPicker},
    data() {
      return {
          title: '请假申请',
          value: '',
          form: {
          },
          CCInfo: {},
          JBInfo: {},
          LZInfo: {},
          QJInfo: {},
          YZInfo: {},
          ZCInfo: {},
          DAInfo: {},
          BXInfo: {
              list:[
                  {name:'项目1', price:'1000'},
                  {name:'项目2', price:'1000'},
                  {name:'项目3', price:'1000'},
                  {name:'项目4', price:'1000'},
                  {name:'项目5', price:'1000'}
              ],
              file:[
                  {url: 'http://localhost/dev-api/profile/upload/2024/08/03/logo_20240803114957A001.jpg'}]
          },
          columns: data.biz_flow_type,
          activeNames: [''],
          biz_flow_type:data.biz_flow_type,
          biz_expense_type:data.biz_expense_type,
          biz_take_off:data.biz_take_off,
          biz_seal_type:data.biz_seal_type,
          biz_vehicle:data.biz_vehicle,
          openId:null,
          SPId:null,
          AbaseURL:null,
      };
    },
      created() {
          // let projectId = this.$route.params.id;
          // debugger
          // let projectId = this.$route.params;
          let flowInfo = this.$route.query;
          this.openId = flowInfo.openId
          this.SPId = flowInfo.SPId
          this.getFlowInfo()

          this.AbaseURL = process.env.NODE_ENV == 'development' ? window._CONFIG['localURL'] : window._CONFIG['domianURL'];

      },
    methods: {
        getFlowInfo() {
            this.get2(this.SPId, "/biz/public/flowlog/").then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        this.form = res.data
                        switch (this.form.type) {
                            case 1:
                                // 出差申请
                                this.CCInfo = JSON.parse(this.form.detail)
                                if (this.CCInfo.file){
                                    let real_src_list = this.CCInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.CCInfo.file = srcList
                                }
                                break
                            case 2:
                                // 加班申请
                                this.JBInfo = JSON.parse(this.form.detail)
                                if (this.JBInfo.file){
                                    let real_src_list = this.JBInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.JBInfo.file = srcList
                                }
                                break
                            case 3:
                                // 离职申请
                                this.LZInfo = JSON.parse(this.form.detail)
                                if (this.LZInfo.file){
                                    let real_src_list = this.LZInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.LZInfo.file = srcList
                                }
                                break
                            case 4:
                                // 请休假申请
                                this.QJInfo = JSON.parse(this.form.detail)
                                if (this.QJInfo.file){
                                    let real_src_list = this.QJInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.QJInfo.file = srcList
                                }
                                break
                            case 5:
                                // 用章申请
                                this.YZInfo = JSON.parse(this.form.detail)
                                if (this.YZInfo.file){
                                    let real_src_list = this.YZInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.YZInfo.file = srcList
                                }
                                break
                            case 6:
                                // 资产申请
                                this.ZCInfo = JSON.parse(this.form.detail)
                                console.log(this.ZCInfo)
                                if (this.ZCInfo.file){
                                    let real_src_list = this.ZCInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.ZCInfo.file = srcList
                                }
                                break
                            case 7:
                                // 档案申请
                                this.DAInfo = JSON.parse(this.form.detail)
                                console.log(this.DAInfo)
                                if (this.DAInfo.file){
                                    let real_src_list = this.DAInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.DAInfo.file = srcList
                                }
                                break
                            case 8:
                                // 报销申请
                                this.BXInfo = JSON.parse(this.form.detail)
                                console.log(this.BXInfo)
                                if (this.BXInfo.file){
                                    let real_src_list = this.BXInfo.file.split(",");
                                    let srcList = [];
                                    real_src_list.forEach(item => {
                                        let info = {
                                            url:this.AbaseURL + item,
                                            deletable: false
                                        }
                                        return srcList.push(info);
                                    });
                                    this.BXInfo.file = srcList
                                }
                                break
                            default:
                                break
                        }
                    } else {

                    }
                } else {
                    Notify(res.msg);
                }
            });
        },
        confirm (data1, index, data2) { // checkbox确定,
            // tips 正常获取值，用不到这个方法，用v-model获取值即可，这个方法是告诉你，可以获取任何你想要的数据
            // data1 当前这一条的obj数据
            // index 当前选择的索引
            // data2 当前这一条数据的value
            // console.log(data1, data2, index)
            this.form.type = data1.id
        },
        typeFormat(row) {
            let actions = [];
            Object.keys(this.biz_flow_type).some((key) => {
                if (this.biz_flow_type[key].id+'' == ('' + row)) {
                    actions.push(this.biz_flow_type[key].name);
                    return true;
                }
            })
            return actions.join('');
        },
        typeFormat1(row) {
            let actions = [];
            Object.keys(this.biz_vehicle).some((key) => {
                if (this.biz_vehicle[key].id+'' == ('' + row)) {
                    actions.push(this.biz_vehicle[key].name);
                    return true;
                }
            })
            return actions.join('');
        },
        typeFormat4(row) {
            let actions = [];
            Object.keys(this.biz_take_off).some((key) => {
                if (this.biz_take_off[key].id+'' == ('' + row)) {
                    actions.push(this.biz_take_off[key].name);
                    return true;
                }
            })
            return actions.join('');
        },
        typeFormat5(row) {
            let actions = [];
            Object.keys(this.biz_seal_type).some((key) => {
                if (this.biz_seal_type[key].id+'' == ('' + row)) {
                    actions.push(this.biz_seal_type[key].name);
                    return true;
                }
            })
            return actions.join('');
        },
        typeFormat8(row) {
            let actions = [];
            Object.keys(this.biz_expense_type).some((key) => {
                if (this.biz_expense_type[key].id+'' == ('' + row)) {
                    actions.push(this.biz_expense_type[key].name);
                    return true;
                }
            })
            return actions.join('');
        },
      onSubmit(values) {
            this.form.openId = this.openId
          this.put(this.form, "/biz/public/flowlog/edit").then((res) => {
              // console.log(res)
              if (res.code === 200) {
                  Notify({ type: 'success', message: '审批成功' });
                  this.getFlowInfo()
              } else {
                  Notify(res.msg);
              }
          });
      },
      onClickLeft(index) {
        Toast('返回');
      },
      onClick(index) {

        Notify('通知内容');
        // Notify({ type: 'primary', message: index });
      },
    },
  }
</script>
<style lang="less">

</style>
